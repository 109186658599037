import { FeatureFlags } from 'src/libs/api/backend/session'
import { RefinementFilter } from '../refinement-filter'
import { AutomateInfoBanner, AutomateInfoBannerInfo, RateThisSearch } from '../automate-info-banner'
import { Flex, Spacer } from 'src/components/primitives'
import { ToggleCandidateView } from '../toggle-candidate-view'
import { useSession } from 'src/hooks/use-session'
import type { Criteria } from 'src/libs/api/backend/candidate_search'
import type { JobSearchRefinement } from 'src/libs/api/backend/jobs'
import { usePrintView } from 'src/hooks/use-print-view'
import { RefinementFilterSummary } from '../refinement-filter/refinement-filter-summary'
import { Paragraph } from 'src/components/primitives/typography'

const getCountText = (count: number | null | undefined): string => {
  if (!count) {
    return ''
  }
  if (count < 50) {
    return '< 50 candidates'
  }
  if (count < 500) {
    return '< 500 candidates'
  }
  if (count < 1000) {
    return '< 1k candidates'
  }
  return count >= 1000 ? `~${Math.round(count / 1000)}k candidates` : `${count} candidates`
}

interface RefinementActionsBarProps {
  jobSearchRefinement?: JobSearchRefinement
  autoApproveIsEnabled: boolean
  isRecommending: boolean
  setIsRecommending: (isRecommending: boolean) => void
  maxWidth: string
  newSearchRefinement?: boolean
  onSubmit: (criteria: Criteria) => void
}

export const RefinementActionsBar = ({
  jobSearchRefinement,
  autoApproveIsEnabled,
  isRecommending,
  setIsRecommending,
  maxWidth,
  newSearchRefinement,
  onSubmit
}: RefinementActionsBarProps): JSX.Element => {
  const { featureFlags } = useSession()
  const isPrintView = usePrintView()
  if (isPrintView && jobSearchRefinement) {
    return (
      <div
        style={{
          width: '100%',
          maxWidth,
          pageBreakBefore: 'avoid',
          pageBreakInside: 'avoid',
          pageBreakAfter: 'avoid'
        }}
      >
        <RefinementFilterSummary jobSearchRefinement={jobSearchRefinement} />
      </div>
    )
  }
  return (
    <div
      style={{
        width: '100%',
        maxWidth,
        marginBottom: 16,
        display: 'flex',
        flexDirection: 'column',
        gap: 8
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 40
        }}
      >
        <Flex $align='center' $gap={10} $justify='flex-start' $flex='1' $width='auto'>
          <RefinementFilter
            jobSearchRefinement={jobSearchRefinement}
            setIsRecommending={setIsRecommending}
            newSearchRefinement={newSearchRefinement}
            isRecommending={isRecommending}
            onSubmit={onSubmit}
          />
          { jobSearchRefinement?.searchStats?.candidatesPassedFiltersCount && (
            <Paragraph size='XS'>
              {getCountText(jobSearchRefinement.searchStats.candidatesPassedFiltersCount)}
            </Paragraph>
          )}
        </Flex>
        <Flex $align='center' $gap={8} $justify='flex-end' $width='auto'>
          {!newSearchRefinement && (
            <>
              <RateThisSearch disabled={isRecommending} />
              <Spacer $size={4} $direction="row" />
            </>
          )}
          {featureFlags?.includes(FeatureFlags.AI_AUTOMATION) &&
            <AutomateInfoBanner autoApproveIsEnabled={autoApproveIsEnabled} disabled={!!newSearchRefinement || isRecommending} />
          }
          <ToggleCandidateView disabled={!!newSearchRefinement || isRecommending} />
        </Flex>
      </div>
      {autoApproveIsEnabled && (
        <AutomateInfoBannerInfo autoApproveIsEnabled={autoApproveIsEnabled} $maxWidth={maxWidth} />
      )}
    </div>
  )
}

import { isNil } from 'lodash'
import { DivAsButton } from 'src/components/primitives/div-as-button'
import { Flex } from 'src/components/primitives/flex'
import { Caption } from 'src/components/primitives/typography'
import {
  CandidateJobSequenceFilterDisplay,
  IN_OUTREACH_STEPS
} from 'src/libs/api/backend/candidate_jobs'
import * as S from './candidates-in-sequence-stats.styled'
import { Fragment, useMemo } from 'react'
import { useCandidateJobCountsQuery } from 'src/hooks/queries/use-candidate-job-counts'
import { useNavigate } from 'react-router-dom'
import RouteBuilder from 'src/libs/route-builder'
import { Icon, Icons } from 'src/components/primitives/icon'
import type { IconName } from 'src/components/primitives/icon'
import { Tooltip } from 'src/components/primitives/tooltip'

interface FilterButtonProps {
  jobId: string
  title: string
  count: number | null | undefined
  currentStep: IN_OUTREACH_STEPS
  step: IN_OUTREACH_STEPS
  suffix?: string | null
  suffixIcon?: IconName
  suffixTooltip?: string
  isLoading?: boolean
}

const calculatePercentage = (count: number | undefined, total: number | undefined): string => {
  if (isNil(count) || isNil(total)) {
    return '0%'
  }
  return `${total > 0 ? Math.round((count / total) * 100) : 0}%`
}

const FilterButton = ({
  jobId,
  title,
  count,
  isLoading = false,
  currentStep,
  step,
  suffix,
  suffixIcon,
  suffixTooltip
}: FilterButtonProps): JSX.Element => {
  const navigate = useNavigate()

  return (
    <DivAsButton
      ariaLabel={`Filter candidates by ${title}`}
      onClick={() => {
        if (step === currentStep) {
          navigate(RouteBuilder.build('JOBS_CANDIDATES_IN_SEQUENCE', { jobId }))
        } else {
          navigate(RouteBuilder.build('JOBS_CANDIDATES_IN_SEQUENCE', { jobId }, { step }))
        }
      }}
      isDisabled={isLoading}
    >
      <S.TopStatFilterButton
        $isActive={step === currentStep}
        $isLoading={isLoading}
      >
        { isLoading
          ? <div />
          : <>
              <Flex $gap={8} $align='center' $flex='1'>
                <Caption size="MD" $fontWeight={700}>
                  {count}
                </Caption>
                <Caption size="XS">{title}</Caption>
              </Flex>
              <Tooltip
                trigger={
                  <S.Suffix>
                    {!isNil(suffix) && (
                      <Caption size="SM" $fontWeight={400} $color="fgTertiary">
                        {suffix}
                      </Caption>
                    )}
                    {suffixIcon && (
                      <Icon name={suffixIcon} size={12} color="fgTertiary" />
                    )}
                  </S.Suffix>
                }
                disabled={!suffixTooltip}
                position="top"
              >
                {suffixTooltip}
              </Tooltip>
            </>
        }
      </S.TopStatFilterButton>
    </DivAsButton>
  )
}

interface ArrowFilterButtonProps extends FilterButtonProps {
  index: number
}

const ArrowFilterButton = ({
  index,
  jobId,
  title,
  count,
  isLoading = false,
  currentStep,
  step,
  suffix
}: ArrowFilterButtonProps): JSX.Element => {
  const navigate = useNavigate()

  const { firstFilter, lastFilter, isActive } = useMemo(() => {
    return {
      firstFilter: index === 0,
      lastFilter: index === 3,
      isActive: step === currentStep
    }
  }, [index, step, currentStep])
  return (
    <DivAsButton
      ariaLabel={`Filter candidates by ${title}`}
      onClick={() => {
        if (step === currentStep) {
          navigate(RouteBuilder.build('JOBS_CANDIDATES_IN_SEQUENCE', { jobId }))
        } else {
          navigate(RouteBuilder.build('JOBS_CANDIDATES_IN_SEQUENCE', { jobId }, { step }))
        }
      }}
      isDisabled={isLoading}
    >
      <S.ArrowFilterButton
        $firstFilter={firstFilter}
        $lastFilter={lastFilter}
        $isActive={isActive}
        $isLoading={isLoading}
      >
        <S.ArrowFilterButtonContent
          $firstFilter={firstFilter}
          $lastFilter={lastFilter}
          $isActive={isActive}
        >
          {isLoading
            ? <div />
            : <>
                <Flex $gap={8} $align='center' $flex='1'>
                  <Caption size="MD" $fontWeight={700}>
                    {count}
                  </Caption>
                  <Caption size="XS">{title}</Caption>
                </Flex>
                <S.Suffix>
                  {!isNil(suffix) && (
                    <Caption size="SM" $fontWeight={400} $color="fgTertiary">
                      {suffix}
                    </Caption>
                  )}
                </S.Suffix>
              </>
          }
        </S.ArrowFilterButtonContent>
      </S.ArrowFilterButton>
    </DivAsButton>
  )
}

interface CandidatesInSequenceStatsProps {
  currentStep: IN_OUTREACH_STEPS
  jobId: string
}

export const CandidatesInSequenceStats = ({ currentStep, jobId }: CandidatesInSequenceStatsProps): JSX.Element => {
  const { isPending, data: candidateJobCounts } = useCandidateJobCountsQuery()
  const { prospectingErrors, sequenceEmailsSentByOrg, candidateInOutreach } = candidateJobCounts ?? {}
  const {
    [IN_OUTREACH_STEPS.ALL_CONTACTS]: allContacts,
    [IN_OUTREACH_STEPS.MESSAGED]: messaged,
    [IN_OUTREACH_STEPS.IN_PROGRESS]: inProgress,
    [IN_OUTREACH_STEPS.OPENED]: opened,
    [IN_OUTREACH_STEPS.REPLIED]: replied,
    [IN_OUTREACH_STEPS.SCHEDULED]: scheduled,
    [IN_OUTREACH_STEPS.NOT_YET_CONTACTED]: notYetContacted,
    [IN_OUTREACH_STEPS.NO_RESPONSE]: noResponse
  } = candidateInOutreach ?? {}

  const topStatsBlock = useMemo(() => {
    return [
      {
        step: IN_OUTREACH_STEPS.ALL_CONTACTS,
        title: CandidateJobSequenceFilterDisplay[IN_OUTREACH_STEPS.ALL_CONTACTS],
        count: allContacts
      },
      {
        step: IN_OUTREACH_STEPS.IN_PROGRESS,
        title: CandidateJobSequenceFilterDisplay[IN_OUTREACH_STEPS.IN_PROGRESS],
        count: inProgress,
        suffix: isNil(sequenceEmailsSentByOrg?.sumToday) ? '0' : sequenceEmailsSentByOrg.sumToday.toString(),
        suffixIcon: Icons.send,
        suffixTooltip: 'Emails sent today'
      },
      {
        step: IN_OUTREACH_STEPS.NOT_YET_CONTACTED,
        title: CandidateJobSequenceFilterDisplay[IN_OUTREACH_STEPS.NOT_YET_CONTACTED],
        count: notYetContacted,
        suffix: isNil(prospectingErrors) ? undefined : prospectingErrors.toString(),
        suffixIcon: Icons.alertTriangle,
        suffixTooltip: 'Candidate errors'
      },
      {
        step: IN_OUTREACH_STEPS.NO_RESPONSE,
        title: CandidateJobSequenceFilterDisplay[IN_OUTREACH_STEPS.NO_RESPONSE],
        count: noResponse
      }
    ]
  }, [
    allContacts,
    inProgress,
    notYetContacted,
    noResponse,
    prospectingErrors,
    sequenceEmailsSentByOrg?.sumToday
  ])

  const bottomStatsBlock = useMemo(() => {
    return [
      {
        step: IN_OUTREACH_STEPS.MESSAGED,
        title: CandidateJobSequenceFilterDisplay[IN_OUTREACH_STEPS.MESSAGED],
        count: messaged
      },
      {
        step: IN_OUTREACH_STEPS.OPENED,
        title: CandidateJobSequenceFilterDisplay[IN_OUTREACH_STEPS.OPENED],
        count: opened,
        suffix: calculatePercentage(opened, messaged)
      },
      {
        step: IN_OUTREACH_STEPS.REPLIED,
        title: CandidateJobSequenceFilterDisplay[IN_OUTREACH_STEPS.REPLIED],
        count: replied,
        suffix: calculatePercentage(replied, messaged)
      },
      {
        step: IN_OUTREACH_STEPS.SCHEDULED,
        title: CandidateJobSequenceFilterDisplay[IN_OUTREACH_STEPS.SCHEDULED],
        count: scheduled,
        suffix: calculatePercentage(scheduled, messaged)
      }
    ]
  }, [messaged, opened, replied, scheduled])

  return (
    <S.Wrapper>
      <S.TopStatsBlock>
        {topStatsBlock.map((block, index) => (
          <Fragment key={block.step}>
            <FilterButton
              jobId={jobId}
              isLoading={isPending}
              currentStep={currentStep}
              {...block}
            />
            {index < topStatsBlock.length - 1 && <S.Separator />}
          </Fragment>
        ))}
      </S.TopStatsBlock>
      <S.BottomStatsBlock>
        {bottomStatsBlock.map((block, index) => (
          <ArrowFilterButton
            key={block.step}
            jobId={jobId}
            isLoading={isPending}
            currentStep={currentStep}
            index={index}
            {...block}
          />
        ))}
      </S.BottomStatsBlock>
    </S.Wrapper>
  )
}
